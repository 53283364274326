import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "gatsby";
import Typed from "react-typed";
import Typography from "../Typography";
import Grid from "../Grid";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import "./style.scss";

const ProductBanner = ({ subtitle, heading, visual, buttons, logo }) => {
  return (
    <div className="product-banner-wrapper">
      <section className="container">
        <div className="product-banner">
          <Grid column xs={12} md={7} lg={6}>
            <img src={logo?.publicURL} height={64} className="mb-0" alt={heading} />
            <div className="position-relative">
              <Typography variant="h1" className="mb-16">
                <Typed
                  strings={[heading]}
                  typeSpeed={30}
                  showCursor={false}
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                />
              </Typography>
              <Typography
                variant="h1"
                component="div"
                aria-hidden="true"
                className="mb-16 opacity-0 pointer-events-none"
              >
                {heading}
              </Typography>
            </div>
            <Typography variant="subtitle" className="mb-48">
              {subtitle}
            </Typography>
            <div className="d-flex m-n8">
              {buttons?.map((button) => {
                if (button?.external) {
                  return (
                    <div className="p-8" key={button?.to}>
                      <a
                        href={button?.to}
                        title={button?.title}
                        className={clsx(
                          "btn btn-size-3 btn-has-overlay rounded-pill align-items-end mr-8",
                          `btn-${button.color ?? "primary"}`
                        )}
                        target={button?.newWindow ? "_blank" : null}
                        rel={button?.newWindow ? "noreferrer" : null}
                      >
                        <span
                          className="btn-overlay"
                          style={{ width: button?.label?.length * 9 + 50, height: button?.label?.length * 9 + 50 }}
                        />
                        <span className="btn-label">{button?.label}</span>
                      </a>
                    </div>
                  );
                }

                return (
                  <div className="p-8" key={button?.to}>
                    <Link
                      to={button?.to}
                      title={button?.title}
                      className={clsx(
                        "btn btn-size-3 btn-has-overlay rounded-pill align-items-end mr-8",
                        `btn-${button.color ?? "primary"}`
                      )}
                    >
                      <span className="btn-overlay" style={{ width: 150, height: 150 }} />
                      <span className="btn-label">{button?.label}</span>
                    </Link>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid column xs={12} md={5} lg={6}>
            <div data-aos="fade-up">
              <PreviewCompatibleImage imageInfo={{ image: visual, alt: heading }} />
            </div>
          </Grid>
        </div>
      </section>
    </div>
  );
};

ProductBanner.propTypes = {
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  visual: PropTypes.object,
  buttons: PropTypes.array,
  logo: PropTypes.object,
};

ProductBanner.defaultProps = {
  heading: "The first cross-chain compatible, delta-one asset protocol.",
  subtitle:
    "linear is the first of its kind trading protocol that enables trading of liquid assets seamlessly and effectively.",
  visual: undefined,
  buttons: [],
  logo: {},
};

export default ProductBanner;
