import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Grid from "../../components/Grid";
import Typography from "../../components/Typography";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import ProductBanner from "../../components/ProductBanner";

export const ProductTemplate = ({ seo, banner, sections, location }) => {
  return (
    <Layout location={location} headerOptions={{ bgColor: "transparent", variant: "fixed-top" }}>
      <Helmet
        title={seo?.title}
        meta={[
          {
            name: "description",
            content: seo?.description,
          },
        ]}
      />
      <ProductBanner
        logo={banner?.logo}
        heading={banner?.heading}
        subtitle={banner?.subtitle}
        visual={banner?.visual}
        buttons={banner?.buttons}
      />
      {(sections ?? []).map((section, idx) => (
        <Grid key={section?.title} container className="secondary-banner py-56" component="section">
          <Grid row align="center" direction={(idx + 1) % 2 === 0 ? "row" : "row-reverse"} justify="center">
            <Grid
              column
              xs={12}
              md={4}
              className={clsx(
                "d-flex justify-content-center justify-content-md-start",
                "mb-16 mb-md-0",
                (idx + 1) % 2 === 0 ? "flex-row-reverse" : "flex-row"
              )}
            >
              <div data-aos="fade-up">
                <PreviewCompatibleImage
                  imageInfo={{ image: section?.visual, alt: section?.title }}
                  style={{ width: 300 }}
                />
              </div>
            </Grid>
            <Grid column xs={12} md={6} lg={4} className="text-center">
              <Typography variant="h2" className="mb-12">
                {section?.title}
              </Typography>
              <Typography variant="paragraph-1" className="mb-24">
                {section?.text}
              </Typography>
              {(section?.buttons ?? []).map((button) => {
                if (button?.external) {
                  return (
                    <a
                      key={button?.to}
                      href={button?.to}
                      title={button?.title}
                      className={clsx(
                        "btn btn-size-3 btn-has-overlay rounded-pill align-items-end mr-8",
                        `btn-${button.color ?? "primary"}`
                      )}
                      target={button?.newWindow ? "_blank" : null}
                      rel={button?.newWindow ? "noreferrer" : null}
                    >
                      <span
                        className="btn-overlay"
                        style={{ width: button?.label?.length * 9 + 50, height: button?.label?.length * 9 + 50 }}
                      />
                      <span className="btn-label">{button?.label}</span>
                    </a>
                  );
                }

                return (
                  <Link
                    key={button?.to}
                    to={button?.to}
                    title={button?.title}
                    className={clsx(
                      "btn btn-size-3 btn-has-overlay rounded-pill align-items-end mr-8",
                      `btn-${button.color ?? "primary"}`
                    )}
                  >
                    <span
                      className="btn-overlay"
                      style={{ width: button?.label?.length * 9 + 50, height: button?.label?.length * 9 + 50 }}
                    />
                    <span className="btn-label">{button?.label}</span>
                  </Link>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Layout>
  );
};

ProductTemplate.propTypes = {
  seo: PropTypes.object,
  sections: PropTypes.array,
  location: PropTypes.object,
  banner: PropTypes.object,
};

ProductTemplate.defaultProps = {
  seo: {},
  sections: [],
  location: undefined,
  banner: {},
};

const ProductPage = ({ data, location }) => {
  const { frontmatter } = data?.markdownRemark || {};
  const { seo, product } = frontmatter ?? {};
  const { sections, banner } = product ?? {};

  return <ProductTemplate seo={seo} sections={sections} banner={banner} location={location} />;
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  location: PropTypes.object,
};

ProductPage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {},
    },
  },
  location: undefined,
};

export default ProductPage;

export const pageQuery = graphql`
  query productPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        seo {
          title
          description
        }
        product {
          banner {
            logo {
              publicURL
            }
            heading
            subtitle
            buttons {
              color
              label
              title
              to
              external
              newWindow
            }
            visual {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          sections {
            buttons {
              color
              label
              title
              to
              external
              newWindow
            }
            text
            title
            visual {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
